import { EmptyObject } from '../../misc/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { renderInputField } from '../form-field';
import { CmsText } from '../../misc/cms-text';
import { hasBranding, getBranding } from '../../misc/session';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import State from '../../reducers/state';

const validators = require('redux-form-validators');

/**
 * Names of the form fields (created with <Field>)
 * Must match the 'name' given to the fields
 * This will be available on form submit
 */
export type PasswordResetRequestFormFields = {
    username: string
};

/**
 * Props for the form, loaded from state
 */
export type PasswordResetRequestStateProps = {
    result: string,

    /**
     * Text object for the component   
     */
    componentText: any
};

/**
 * Dispatch props
 */
export type PasswordResetRequestDispatchProps = {
    submitPasswordResetRequest: (values: PasswordResetRequestFormFields) => ThunkAction<Promise<void>, State.All, unknown, AnyAction>
};

/**
 * All combined props
 */
export type PasswordResetRequestProps = 
    PasswordResetRequestDispatchProps 
    & PasswordResetRequestStateProps;

/**
 * The password reset component
 */
class PasswordResetRequest extends React.Component<PasswordResetRequestProps & InjectedFormProps<PasswordResetRequestFormFields, PasswordResetRequestProps>, EmptyObject> {

    getRedirectUrl(): string {
        if (hasBranding()) {
            return `/${getBranding().name}`;
        }
        return '/';
    }
    
    render() {
        let cmsText: CmsText = new CmsText(this.props.componentText, 'password reset request');
        let disableSubmit = this.props.submitting || this.props.pristine;

        return (
            <div className="content">
                <div className="panel-info">
                    <div className="form-control-static signinpanel-greeting">
                        {cmsText.get('passwordResetTitle', 'password reset')}
                    </div>
                    <p className="signinpanel-statement">
                        {cmsText.get('passwordResetDescription', 'If you have forgotten your username, please contact your administrator')}
                    </p>
                    <div className="panel-border" />
                </div>
                <Form 
                    onSubmit={this.props.handleSubmit(data => {
                        this.props.submitPasswordResetRequest(data)
                    })} 
                    className="passwordresetrequest-form" 
                    autoComplete="off">
                    {this.props.result.length > 0 && <p className="form-error">{this.props.result}</p>}
                    <Field
                        id="passwordResetRequestUsername"
                        className="form-field-input"
                        name="username"
                        label={cmsText.get('userName', 'username')}
                        component={renderInputField}
                        type="text"
                        validate={[
                            validators.required({ msg: cmsText.get('userNameRequired', 'required') })
                        ]}
                        autoComplete="off"
                    />
                    <div className="form-button">
                        <button type="submit" className="accept" disabled={disableSubmit}>
                            {cmsText.get('resetPassword', 'Reset Password')}
                        </button>
                    </div>
                </Form>
                <div className="signin-nav-link">
                    <Link to={this.getRedirectUrl()}>
                        {cmsText.get('cancel', 'Cancel')}
                    </Link>
                </div>
            </div>
        );
    }
}

const PasswordResetRequestForm = reduxForm<PasswordResetRequestFormFields, PasswordResetRequestProps>({
    form: 'passwordresetrequest'
})(PasswordResetRequest);

export default PasswordResetRequestForm;