import { EmptyObject } from '../../misc/common';
import { renderInputField } from '../form-field';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { CmsText } from '../../misc/cms-text';
import { ErrorReason } from '../../misc/models';
import { required } from 'redux-form-validators';
import { ThunkAction } from 'redux-thunk';
import State from '../../reducers/state';
import { AnyAction } from 'redux';
/**
 * The route parameters for the parent routing page. Must match what is in App.tsx path.
 * Needs to be used within child components.
 */
export type SigninRouteProps = {
    initialError?: string,
    brandingName?: string,
    isPreview?: boolean 
};

/**
 * Names of the form fields (created with <Field>)
 * Must match the 'name' given to the fields
 * This will be available on form submit
 */
export type SigninFormFields = {
    username: string,
    password: string
};

/**
 * Props for the sign in form, loaded from state
 */
export type SigninStateProps = {
    errorReason: ErrorReason,
    errorMessage: string,
    componentText: any,
    privacyPolicyAccepted: boolean,
    privacyPolicyClientId: number,
    signInSuccessful: boolean
};

/**
 * Dispatch props for the sign in form
 */
export type SigninDispatchProps = {
    submitSignin: (values: SigninFormFields) => ThunkAction<Promise<void>, State.All, unknown, AnyAction>,
    raiseFailure: (reason: ErrorReason) => void,
};

/**
 * All combined props used for the sign in form
 */
export type SigninProps = 
    SigninDispatchProps 
    & SigninStateProps 
    & SigninRouteProps;

/**
 * The sign in component
 */
class Signin extends React.Component<SigninProps & InjectedFormProps<SigninFormFields, SigninProps>, EmptyObject> {
    render() {        
        let cmsText: CmsText = new CmsText(this.props.componentText, 'Signin');
        let disableNavigation = this.props.submitting;
        let disableSubmit = disableNavigation || this.props.pristine;

        return (
            <div className="content">
                <div className="panel-info">
                    <h1 className="form-control-static signinpanel-greeting">{cmsText.get('welcomeMessage', 'welcome')}
                        <span className="signinpanel-greeting-accent">{cmsText.get('instrumentName', 'KornFerry Assessment Systems')}</span>
                    </h1>
                    <h2 className="signinpanel-statement">
                        {cmsText.get('signinText', 'Please sign in')}
                    </h2>
                    <div className="panel-border" />
                </div>
                <Form 
                    onSubmit={this.props.handleSubmit(data => {
                        this.props.submitSignin(data);
                    })} 
                    className="signin-form" 
                    autoComplete="off">
                    {this.props.errorMessage.length > 0 && <p className="form-error" role="alert">{this.props.errorMessage}</p>}
                    <Field
                        id="signinUsername" 
                        className="form-field-input"
                        name="username"
                        label={cmsText.get('userName', 'username')}
                        component={renderInputField}
                        type="text"
                        validate={[
                            required({ msg: cmsText.get('userNameRequired', 'required') })
                        ]}
                        autoComplete="username"
                    />
                    <Field
                        id="signinPassword"
                        className="form-field-input"
                        name="password"
                        label={cmsText.get('password', 'password')}
                        component={renderInputField}
                        type="password"
                        validate={[
                            required({ msg: cmsText.get('passwordRequired', 'required') })
                        ]}
                        autoComplete="current-password"
                    />
                    <div className="form-button">
                        <button className="accept" type="submit" disabled={disableSubmit}>
                            {cmsText.get('signinButton', 'signin')}
                        </button>
                    </div>
                </Form>
                <div className="signin-nav-link">
                    <Link to="/password-reset-request" className={(disableNavigation ? 'disabled-link' : '')}>
                        {cmsText.get('forgotPassword', 'forgot password')}
                    </Link>
                </div>
            </div>
        );
    }
}

const SigninForm = reduxForm<SigninFormFields, SigninProps>({
    form: 'signin'
})(Signin);

export default SigninForm;