import { CmsManifestMetaData } from '../misc/cms-manifest-metadata';
import * as Models from '../misc/models';
import { AppService } from './app-service';

/**
 * Get the settings for an open-invitation
 */
export function GetSettings(id: string): Promise<Models.OpenInvitationSettings> {
    let params: AppService.GetParam[] = [];
    params.push(new AppService.GetParam('id', id));
    return AppService.GetThenToObject('openinvitation/settings', params, Models.OpenInvitationSettings);
}

/**
 * Submit registration details to an open invitation
 */
export function submitOpenInvitation(
    id: string, 
    forename: string,
    middlename: string,
    surname: string,
    email: string,
    confirmEmail: string,
    recaptchaResponse: string,
    languageId: number,
    privacyPolicyCustomCheckboxAccepted: boolean | null,
    externalMetadata: Models.KeyValuePair[],
    manifestMetaData: CmsManifestMetaData | null): Promise<Models.OpenInvitationRegisterResult> {
    let body = JSON.stringify({ id, forename, middlename, surname, email, confirmEmail, recaptchaResponse, languageId, privacyPolicyCustomCheckboxAccepted, externalMetadata, manifest: manifestMetaData });
    return AppService.PostThenToObject<Models.OpenInvitationRegisterResult>('openinvitation/register', body, Models.OpenInvitationRegisterResult);
}