import { EmptyObject } from '../misc/common';
import * as React from 'react';
import { connect, MapDispatchToProps } from 'react-redux';
import State from '../reducers/state';
import SigninBoxContainer from './signin-box';
import * as Models from '../misc/models';
import OpenInvitation, { OpenInvitationProps, OpenInvitationDispatchProps, OpenInvitationFormFields } from '../components/open-invitation/open-invitation';
import SsoEmailGate, { SsoEmailGateProps, SsoEmailGateDispatchProps, SsoEmailGateFormFields } from '../components/sso-email-gate/sso-email-gate';
import * as OpenInvitationActions from '../actions/open-invitation';
import PrivacyPolicy, { PrivacyPolicyStateProps } from '../components/privacy-policy/privacy-policy';
import { change } from 'redux-form';
import { Navigate } from 'react-router-dom';
import { Loading } from '../components/loading/loading';
import ContentWrapper from '../components/contentWrapper';
import CmsDocumentTitle from '../containers/cms-document-title';
import ProjectExpired from '../pages/project-expired';
import { ExpiredProjectLoginMethod } from '../misc/models';
import { Dispatch } from 'redux';

const mapStateToProps = (state: State.All) => ({
    hasSettings: state.openinvitation.hasSettings,
    isActive: state.openinvitation.isActive,
    privacyPolicyRequired: state.openinvitation.privacyPolicyRequired,
    privacyPolicyClientId: state.openinvitation.privacyPolicyClintId,
    showCaptcha: state.openinvitation.showCaptcha,
    errorMessage: state.openinvitation.errorMessage,
    hasSuccessMessage: state.openinvitation.hasSuccessMessage,
    registerError: state.openinvitation.error,
    isProjectExpired: state.openinvitation.isProjectExpired,
    componentText: state.signinbox.alltext.openInvitation,
    captchaLanguageCulture: state.signinbox.language.googleCode,
    redirectUrl: '',
    stage: state.openinvitation.stage,
    email: state.openinvitation.email,
    // Privacy policy
    privacyComponentText: state.signinbox.alltext.privacy,
    docMetaData: state.signinbox.alltext.docMetaData,
    hasCheckedForCustomPolicy: state.privacypolicy.hasCheckedForCustomPolicy,
    customPrivacyHeader: state.privacypolicy.customPrivacyHeader,
    customPrivacyStatement: state.privacypolicy.customPrivacyStatement,
    customCheckboxStatement: state.privacypolicy.customCheckboxStatement,
    customTransferringDataStatement: state.privacypolicy.customTransferringDataStatement,
    customAcceptButtonStatement: state.privacypolicy.customAcceptButtonStatement,
    customCancelButtonStatement: state.privacypolicy.customCancelButtonStatement,
    languageId: state.signinbox.language.id,
    privacyPolicyCustomCheckboxAccepted: state.privacypolicy.customCheckboxAccepted,
    hasError: state.privacypolicy.hasError
});

// Note - subtle difference here in the Dispatch, where it needs to have an any type, so it can handle ThunkActions
const mapDispatchToProps: MapDispatchToProps<OpenInvitationDispatchProps | SsoEmailGateDispatchProps, EmptyObject> = (dispatch: Dispatch<any>) => ({
    getSettings(id: string) {
        dispatch(OpenInvitationActions.getSettings(id));
    },
    submitOpenInvitation(values: OpenInvitationFormFields) {
        return dispatch(OpenInvitationActions.submitOpenInvitation(values.forename, values.middlename, values.surname, values.email, values.confirmEmail, values.captchaResponse, values.privacyPolicyCustomCheckboxAccepted, values.externalMetadata, values.manifestMetaData));
    },
    submitSsoEmailGate(values: SsoEmailGateFormFields) {
        return dispatch(OpenInvitationActions.submitSsoEmailGate(values.email, values.captchaResponse, values.privacyPolicyCustomCheckboxAccepted, values.manifestMetaData));
    },
    captchaComplete(formName: string, response: string) {
        // Use the redux-form change event to change the form field value, so we have it in the form data when submitting the form
        dispatch(change(formName, 'captchaResponse', response));
    },
    formFieldChange(formName: string, fieldName: string, value: any) {
        dispatch(change(formName, fieldName, value));
    }
});

class OpenInvitationContainer extends React.Component<OpenInvitationProps & SsoEmailGateProps, PrivacyPolicyStateProps> implements React.ComponentLifecycle<OpenInvitationProps & SsoEmailGateProps, PrivacyPolicyStateProps> {
    componentDidMount() {
        // Only get the settings on first load, hasSettings is set in the reducer after the settings have been loaded.
        if (!this.props.hasSettings) {
            this.props.getSettings(this.props.id);
        }
    }

    render() {
        let showLoading = !this.props.hasSettings;
        let notActive = !showLoading && !this.props.isActive;
        let showPrivacyPolicy = !showLoading && !notActive && this.props.privacyPolicyRequired && !this.props.ppaccepted;
        let isProjectExpired = this.props.isProjectExpired;

        if (isProjectExpired) {
            return ProjectExpired(ExpiredProjectLoginMethod.ProjectRegistrationLink);
        }

        if (notActive) {
            return <Navigate replace to={`/${Models.ErrorReason.LoginSelfServiceFailed}`} />;
        }

        if (showLoading) {
            return <Loading />;
        }

        return (
            <ContentWrapper>
                <CmsDocumentTitle titlePath="openInvitation.documentTitle">
                    <div>
                        {showPrivacyPolicy && 
                            <SigninBoxContainer>
                                <PrivacyPolicy 
                                    clientId={this.props.privacyPolicyClientId} 
                                    internalRedirectUrl={'/open-invitation/' + this.props.id + '/true' + location.search} 
                                    internalErrorRedirectUrl={'/open-invitation/' + this.props.id + location.search} />
                            </SigninBoxContainer>
                        }
                        {!showPrivacyPolicy && this.props.stage === Models.OpenInvitationStage.Form && 
                            <SigninBoxContainer>
                                <OpenInvitation {...this.props} />
                            </SigninBoxContainer>
                        }
                        {!showPrivacyPolicy && this.props.stage === Models.OpenInvitationStage.SsoEmailGate && 
                            <SigninBoxContainer>
                                <SsoEmailGate {...this.props} />
                            </SigninBoxContainer>
                        }
                    </div>
                </CmsDocumentTitle>
            </ContentWrapper>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpenInvitationContainer);
