import { ThunkAction } from 'redux-thunk';
import { Action, createAction } from './action';
import { ExternalRedirect, ErrorReasonTextAction } from '../misc/common';
import * as Models from '../misc/models';
import * as SigninService from '../services/signin-service';
import State from '../reducers/state';
import { CmsService } from '../services/cms-service';
import { changeLanguageSuccess } from './signin-box';
import { ErrorReason, SigninError } from '../misc/models';
import { AnyAction, Dispatch } from 'redux';

// Sign in failure action
export const SigninFailureType = 'SIGNIN_FAILURE';
export const SignInSuccessType = 'SIGNIN_SUCCESS';
export const SignInClearFailureType = 'SIGNIN_CLEARFAILURE';
export type SigninFailureAction = Action<typeof SigninFailureType, SigninError>;
export type SigninSuccessAction = Action<typeof SignInSuccessType, Models.AuthenticationResult>;
export type SigninClearFailureAction = Action<typeof SignInClearFailureType, null>;

export function signinClearFailure(): SigninClearFailureAction {
    return createAction(SignInClearFailureType, null);
}

export function signinFailure(error: SigninError): SigninFailureAction {
    return createAction(SigninFailureType, error);
}

export function signinSuccess(signInResult: Models.AuthenticationResult): SigninSuccessAction {
    return createAction(SignInSuccessType, signInResult);
}

export function signinFailureFromReason(errorReason: ErrorReason): ThunkAction<void, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        let error: SigninError = {
            errorReason: errorReason,
            errorMessage: dispatch(ErrorReasonTextAction(errorReason))
        };
        dispatch(signinFailure(error));
    };
}

// Submit sign in action. Returns a Promise<void> so that redux-form knows when submit is in-progress
export function submitSignin(username: string, password: string, languageId: number): ThunkAction<Promise<void>, State.All, unknown, AnyAction> {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(signinClearFailure());
            SigninService.SubmitSignin(username, password, languageId)
                .then(r => {
                    let signinResult = new Models.SigninResult();
                    Object.assign(signinResult, r.signinResult);
                    let isSigninSuccessful = signinResult.isSuccessful;
                    let errorReason = signinResult.errorReason;

                    /**
                     * If user cannot login because of projects are expired,
                     * We must change the system language and show the error in the user's language.
                     */
                    if (isSigninSuccessful || signinResult.allProjectsExpiredAndIncomplete()) {
                        CmsService.SetLanguageChangesAttributeInSessionStorage(false);
                        let changeSiteLanguage = languageId === 0 && r.signinResult.languageId !== 0;
                        let state = getState();
                        let brandingName: string | undefined = state.branding.hasSignInManifest ? state.branding.name : undefined;
                        let promise = changeSiteLanguage
                            ? CmsService.GetLoginSiteText(r.signinResult.languageId, state.branding.isPreview, brandingName)
                            : new Promise<void>(resolveFakePromise => resolveFakePromise());

                        promise.then(r2 => {
                            if (changeSiteLanguage) {
                                dispatch(changeLanguageSuccess(r.signinResult.languageId, r2));
                            }

                            if (isSigninSuccessful) {
                                if (r.signinResult.acceptedPrivacyPolicy) {
                                    ExternalRedirect(r.signinResult.redirectUrl);
                                } else {
                                    dispatch(signinSuccess(r));
                                    resolve();
                                }
                            } else {
                                onSigninError(errorReason, dispatch, resolve);
                            }
                        });
                    } else {
                        onSigninError(errorReason, dispatch, resolve);
                    }
                })
                // TODO - some proper error reporting
                .catch(r => { onSigninError(ErrorReason.LoginCredential, dispatch, resolve); });
        });
    };
}

function onSigninError(errorReason: ErrorReason, dispatch: Dispatch<any>, resolve: () => void | PromiseLike<void>) {
    dispatch(signinFailureFromReason(errorReason));
    resolve();
}

export type Actions = SigninFailureAction | SigninSuccessAction | SigninClearFailureAction;