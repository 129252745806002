import React from 'react';
import { EmptyObject } from '../../misc/common';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { renderInputField } from '../form-field';
import ReCaptcha from '../recaptcha/recaptcha';
import { Navigate } from 'react-router';
import { CmsText } from '../../misc/cms-text';
import format from 'string-template';
import { OpenInvitationStage, KeyValuePair } from '../../misc/models';
import { CmsManifestMetaData } from '../../misc/cms-manifest-metadata';
import renderHTML from 'html-react-parser';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import State from '../../reducers/state';

const validators = require('redux-form-validators');

/**
 * The route parameters for the parent routing page. Must match what is in App.tsx path.
 * Needs to be used within child components.
 */
export type OpenInvitationRouteProps = {
    /**
     * The self-service settings id
     */
    id: string,
    /**
     * Whether or not the privacy policy has been previously accepted
     */
    ppaccepted: boolean | string,
    /**
     * Any external metadata specified in the querystring
     */
    externalmetadata: KeyValuePair[]
};

/**
 * Names of the form fields (created with <Field>)
 * Must match the 'name' given to the fields
 * This will be available on form submit
 */
export type OpenInvitationFormFields = {
    forename: string,
    middlename: string,
    surname: string,
    email: string,
    confirmEmail: string,
    captchaResponse: string,
    privacyPolicyCustomCheckboxAccepted: boolean | null,
    externalMetadata: KeyValuePair[],
    manifestMetaData: CmsManifestMetaData
};

export type OpenInvitationStateProps = {
    hasSettings: boolean;
    isActive: boolean;
    privacyPolicyRequired: boolean;
    privacyPolicyClientId: number;
    showCaptcha: boolean;
    hasSuccessMessage: boolean;
    errorMessage: string;
    registerError: number;
    componentText: any;
    docMetaData: any;
    captchaLanguageCulture: string;
    privacyPolicyCustomCheckboxAccepted: boolean | null;
    stage: OpenInvitationStage;
    email: string;
    isProjectExpired: boolean;
};

type OpenInvitationInternalState = {
    isCaptchaComplete: boolean;
};

export type OpenInvitationProps = 
    OpenInvitationRouteProps 
    & OpenInvitationDispatchProps 
    & OpenInvitationStateProps;

export type OpenInvitationDispatchProps = {
    getSettings(id: string): void;
    submitOpenInvitation(values: OpenInvitationFormFields): ThunkAction<Promise<void>, State.All, unknown, AnyAction>;
    captchaComplete(formName: string, response: string): void;
    formFieldChange(formName: string, fieldName: string, value: any): void;
};

class OpenInvitation extends React.Component<OpenInvitationProps & InjectedFormProps<OpenInvitationFormFields, OpenInvitationProps>, OpenInvitationInternalState> implements React.ComponentLifecycle<OpenInvitationProps, EmptyObject> {
    constructor(props: OpenInvitationProps & InjectedFormProps<OpenInvitationFormFields, OpenInvitationProps>) {
        super(props);
        this.state = {
            isCaptchaComplete: !props.showCaptcha
        };
        this.captchaComplete = this.captchaComplete.bind(this);
    }

    componentDidMount() {
        this.props.initialize({ email: this.props.email, confirmEmail: this.props.email });

        // Set the values of form fields that are not displayed. This enables values to be available on submit
        this.props.formFieldChange(OpenInvitationFormName, 'privacyPolicyCustomCheckboxAccepted', this.props.privacyPolicyCustomCheckboxAccepted);
        this.props.formFieldChange(OpenInvitationFormName, 'externalMetadata', this.props.externalmetadata);
        this.props.formFieldChange(OpenInvitationFormName, 'manifestMetaData', new CmsManifestMetaData(this.props.docMetaData));
    }

    captchaComplete(response: string) {
        this.props.captchaComplete(OpenInvitationFormName, response);

        this.setState({
            isCaptchaComplete: true
        });
    }

    render() {
        let cmsText: CmsText = new CmsText(this.props.componentText, 'Open Invitation');
        let disableSubmit = this.props.submitting || this.props.pristine || !this.state.isCaptchaComplete;
        
        if (this.props.hasSuccessMessage) {
            return (
                <Navigate replace to={`/${this.props.registerError}`} />
            );
        } else {
            return (
                <div className="content">
                    <div className="panel-info">
                        <div className="form-control-static signinpanel-greeting">
                            <h4 className="signinpanel-greeting-accent">
                                {cmsText.get('personalDetails', 'Personal details')}
                            </h4>
                        </div>
                        <p className="signinpanel-statement">
                            {renderHTML(format(cmsText.get('alreadyRegistered', 'already Registered <a {signInhref}>sign in</a>'), {
                                'signInhref': 'href=\'/\'className=\'signinpanel-statement-link\' aria-describedby="signInLinkAdditionalInfo"'
                            }))}
                        </p>
                        <span id="signInLinkAdditionalInfo" className="signinpanel-no-display">
                        : {cmsText.get('signInAccessibilityInstruction', 'Press Enter to sign in to Assessments')}
                        </span>
                        <p className="signinpanel-statement">
                            {cmsText.get('enterDetails', 'Please enter your name and email address below, then click the save button.')}
                        </p>
                        <div className="panel-border" />
                    </div>
                    <Form 
                        onSubmit={this.props.handleSubmit(data => {
                            this.props.submitOpenInvitation(data);
                        })} 
                        className="signin-form" 
                        autoComplete="off">
                        {this.props.errorMessage.length > 0 && <p className="form-error">{this.props.errorMessage}</p>}
                        <Field
                            id="oiForename"
                            className="form-field-input"
                            name="forename"
                            label={cmsText.get('firstName', 'First name')}
                            component={renderInputField}
                            type="text"
                            validate={[
                                validators.required({ msg: cmsText.get('firstNameRequired', 'required') })
                            ]}
                            autoComplete="given-name"
                        />
                        <Field
                            id="oiMiddlename"
                            className="form-field-input"
                            name="middlename"
                            label={cmsText.get('middleName', 'Middle name')}
                            component={renderInputField}
                            type="text"
                            autoComplete="additional-name"
                        />
                        <Field
                            id="oiSurname"
                            className="form-field-input"
                            name="surname"
                            label={cmsText.get('lastName', 'Last name')}
                            component={renderInputField}
                            type="text"
                            validate={[
                                validators.required({ msg: cmsText.get('lastNameRequired', 'required') })
                            ]}
                            autoComplete="family-name"
                        />
                        <Field
                            id="oiEmail"
                            className="form-field-input"
                            name="email"
                            label={cmsText.get('email', 'Email')}
                            component={renderInputField}
                            type="text"
                            validate={[
                                validators.required({ msg: cmsText.get('emailRequired', 'required') }),
                                validators.email({ msg: cmsText.get('invalidEmail', 'Invalid email address') }),
                                validators.email({ domainBlacklist: ['*.ru', '*.cu', '*.kp', '*.ir', '*.sy'], msg: cmsText.get('embargoedCountriesSanctionsText', 'Unfortunately, due to current sanctions, Korn Ferry cannot provide services to any individuals located in Cuba, Iran, North Korea, Russia, Syria.') })
                            ]}
                            autoComplete="email"
                        />
                        <Field
                            id="oiConfirmEmail"
                            className="form-field-input"
                            name="confirmEmail"
                            label={cmsText.get('confirmEmail', 'Confirm email')}
                            component={renderInputField}
                            type="text"
                            validate={[
                                validators.required({ msg: cmsText.get('confirmEmailRequired', 'required') }), 
                                validators.confirmation({ field: 'email', msg: cmsText.get('emailMismatch', 'Email addresses do not match') })
                            ]}
                            autoComplete="email"
                        />
                        <div className="form-control-static form-field-input">
                            {/* key is per language because we want a *new* component per language, rather than the same updated component. */}
                            { this.props.showCaptcha && <ReCaptcha 
                                                            captchaComplete={this.captchaComplete} 
                                                            languageCulture={this.props.captchaLanguageCulture} 
                                                            key={'recaptcha' + this.props.captchaLanguageCulture} 
                                                        />
                            }
                        </div>
                        <div className="form-button">
                            <button
                                className="accept" type="submit"
                                disabled={disableSubmit}
                                aria-describedby="saveButtonAdditionalInfo"
                            >
                                {cmsText.get('save', 'Save')}
                            </button>
                            <span id="saveButtonAdditionalInfo" className="signinpanel-no-display">
                            : {cmsText.get('saveButtonAccessibilityInstruction', 'Press Enter to save and continue')}
                            </span>
                        </div>
                    </Form>
                </div>
            );
        }
    }
}

export const OpenInvitationFormName = 'openinvitation';

const OpenInvitationForm = reduxForm<OpenInvitationFormFields, OpenInvitationProps>({
    form: OpenInvitationFormName
})(OpenInvitation);

export default OpenInvitationForm;