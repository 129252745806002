import { EmptyObject } from '../../misc/common';
import * as React from 'react';
const Script = require('react-load-script');
const ReCAPTCHA = require('react-recaptcha');

/**
 * Props for the ReCaptcha component
 */
export type ReCaptchaProps = {
    /**
     * When the user has successfully completed the captcha.
     * The response is the token that should be validated with Google.
     */
    captchaComplete: (response: string) => void,

    /**
     * The language culture code, as accepted by Google ReCaptcha
     */
    languageCulture: string
};

type ReCaptchaState = {
    scriptLoaded: boolean
};

/**
 * Recaptcha component.
 */
class ReCaptcha extends React.Component<ReCaptchaProps, ReCaptchaState> {
    constructor(props: ReCaptchaProps) {
        super(props);
        this.state = { scriptLoaded: false };
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true });
    }

    /**
     * Required for ReCAPTCHA component but we have no use for it.
     */
    callback() {
        // Empty block
    }

    render() {
        const siteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
        return (
            <div>
                <Script url={'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=' + this.props.languageCulture} onLoad={this.handleScriptLoad} />
                {this.state.scriptLoaded &&

                    <ReCAPTCHA sitekey={siteKey} render="explicit" verifyCallback={this.props.captchaComplete} hl={this.props.languageCulture} onloadCallback={this.callback} />
                }
            </div>
        );
    }
}

export default ReCaptcha;