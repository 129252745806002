import { EmptyObject } from '../../misc/common';
import { renderInputField, PasswordValidator } from '../form-field';
import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { CmsText } from '../../misc/cms-text';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import State from '../../reducers/state';

const validators = require('redux-form-validators');

/**
 * The route parameters for the parent routing page. Must match what is in App.tsx path.
 * Needs to be used within child components.
 */
export type PasswordResetRouteProps = {
    token: string
};

/**
 * Names of the form fields (created with <Field>)
 * Must match the 'name' given to the fields
 * This will be available on form submit
 */
export type PasswordResetFormFields = {
    password: string,
    verifyPassword: string
};

/**
 * Props for the reset form, loaded from state
 */
export type PasswordResetStateProps = {
    username: string,
    tokenError: number,
    errorMessage: string,

    /**
     * Text object for the component   
     */
    componentText: any,

    /**
     * Whether or not the token has been validated
     */
    tokenValidated: boolean,
    /**
     * Whether or not the token is valid.
     */
    tokenIsValid: boolean,
    /**
     * Whether or not the actual password reset was a success
     */
    isSuccess: boolean,
};

/**
 * Dispatch props
 */
export type PasswordResetDispatchProps = {
    submitPasswordReset: (values: PasswordResetFormFields) => ThunkAction<Promise<void>, State.All, unknown, AnyAction>,
    validateToken: (token: string) => void;
};

/**
 * All combined props
 */
export type PasswordResetProps = 
    PasswordResetDispatchProps 
    & PasswordResetStateProps 
    & PasswordResetRouteProps;

/**
 * The password reset component
 */
class PasswordReset extends React.Component<PasswordResetProps & InjectedFormProps<PasswordResetFormFields, PasswordResetProps>, EmptyObject> {
    render() {
        let disableNavigation = this.props.submitting;
        let disableSubmit = disableNavigation || this.props.pristine || !this.props.tokenValidated;
        let redirect = this.props.isSuccess || (this.props.tokenValidated && !this.props.tokenIsValid);
        let cmsText: CmsText = new CmsText(this.props.componentText, 'password reset');

        return (
            <div className="content">

                {redirect && <Navigate replace to={`/${this.props.tokenError}`} />}

                <div className="panel-info">
                    <div className="form-control-static signinpanel-greeting">
                        {cmsText.get('documentTitle', 'Password Reset')}
                    </div>
                    <div className="panel-border" />
                </div>
                <Form 
                    onSubmit={this.props.handleSubmit(data => {
                        this.props.submitPasswordReset(data)
                    })} 
                    className="signin-form" 
                    autoComplete="off">
                    {this.props.errorMessage.length > 0 && <p className="form-error">{this.props.errorMessage}</p>}
                    <p className="username">
                        {this.props.username}
                    </p>
                    <Field
                        id="resetPassword"
                        className="form-field-input"
                        name="password"
                        label={cmsText.get('newPassword', 'New password')}
                        component={renderInputField}
                        type="password"
                        validate={[
                            validators.required({ msg: cmsText.get('passwordRequired', 'required') }), 
                            PasswordValidator()
                        ]}
                        autoComplete="off"
                    />
                    <Field
                        id="resetPasswordRepeated"
                        className="form-field-input"
                        name="verifyPassword"
                        label={cmsText.get('confirmPassword', 'Confirm password')}
                        component={renderInputField}
                        type="password"
                        validate={[
                            validators.required({ msg: cmsText.get('confirmPasswordRequired', 'required') }), 
                            validators.confirmation({ field: 'password', msg: cmsText.get('confirmPasswordRequired', 'required') })
                        ]}
                        autoComplete="off"
                    />
                    <div className="form-button">
                        <button className="accept" type="submit" disabled={disableSubmit}>
                            {cmsText.get('resetPassword', 'Reset Password')}
                        </button>
                    </div>
                </Form>
                <div className="signin-nav-link">
                    <Link to="/" replace={true}>
                        {cmsText.get('cancel', 'Cancel')}
                    </Link>
                </div>
            </div>
        );
    }
}

const PasswordResetForm = reduxForm<PasswordResetFormFields, PasswordResetProps>({
    form: 'passwordreset'
})(PasswordReset);

export default PasswordResetForm;