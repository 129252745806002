import { WrappedFieldMetaProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';

const validators = require('redux-form-validators');

type RenderInputFieldType = {
    input: any,
    label: string,
    type: string,
    id: string,
    containerClass: string,
    autoComplete?: string,
    meta: WrappedFieldMetaProps
};

/**
 * Renders an input and label.
 * Use as <Field component={renderInputField}>
 * Any additional property can be put on the <Field>, added to RenderInputFieldType and used in this method
 * @param args The args, as defined as seperate properties on the <Field> and added to RenderInputFieldType
 */
export function renderInputField(args: RenderInputFieldType): JSX.Element {
    let hasError: boolean = args.meta.touched && args.meta.error;
    let feedbackId: string = hasError ? args.id + '-feedback' : '';
    let containerClass = (args.containerClass && args.containerClass.length > 0 ? args.containerClass : '') + (hasError ? 'invalid' : '');

    return (
        <div className={containerClass + ' form-field-input'}>
            <label htmlFor={args.id}>{args.label}</label>{hasError && <span className="form-control-feedback" id={feedbackId}>{args.meta.error}</span>}
            <input {...args.input} autoComplete={args.autoComplete} placeholder={args.label} type={args.type} id={args.id}/>
        </div>);
}

// Custom password validator
const passwordRegexes: string[] = [
    '[a-z]+', // lowercase
    '[A-Z]+', // uppercase
    '[0-9]+', // digit
    '[\!\£\$\^\*\-_\`\[\]\@\#\~\:\|\/\\\,\.\¬]+' // special
];

const PASSWORD_ERROR = <FormattedMessage id="form.errors.alpha" defaultMessage="The password must be at least 8 characters long, and contain at least 3 of the following: upper case letters, lower case letters, numbers and special characters (e.g. # or %). It must not have been used as one of your previous 12 passwords, and must not be your email address or username, nor a common password permutation, e.g. P4ssw0rd." />;

export const PasswordValidator = validators.addValidator({
    defaultMessage: PASSWORD_ERROR,
    validator: function (options: any, value: string, allValues: any) {
        let matches = 0;
        passwordRegexes.forEach(reg => {
            let regex = new RegExp(reg);
            if (regex.test(value)) {
                matches++;
            }
        });

        return value.length >= 8 && matches >= 3;
    }
});
