import { logErrorWithOutAlert } from './error';

/**
 * Class to handle text for components.  
 */
export class CmsText {

    // fields
    private componentTextObject: any;
    private componentName: string;

    /**
     * Constructor.
     * @param componentTextObject The root text component from the CMS system
     * @param componentName The name of the component to use in error messages.
     * @param basePath The path to the base of the component text and use for the basis of the content. e.g. dashboard.headings
     */
    constructor(componentTextObject: any, componentName: string, basePath: string = '') {      
        this.componentTextObject = this.getRootObject(componentTextObject, basePath);
        this.componentName = componentName;
    }

    /**
     * Create a new CmsText object from the current one with the basis at basePath
     */
    getChildCmsText(componentName: string, basePath: string): CmsText {        
        return new CmsText(this.componentTextObject, componentName, basePath);
    }

    /**
     * Get the get for the passed in fieldName
     * @param {string} fieldName 
     * @param {string} defaultValue 
     * @param {boolean} isRequired
     * @returns {String}
     */
    get(fieldName: string, defaultValue: string, isRequired: boolean = true): string {
        let fieldText: string;
        try {
            let fieldParts = this.getTextParts(fieldName);
            let field = fieldParts.pop();
            if (field === undefined) {
                if (isRequired) {
                    throw `Error: can't find text for field: ${fieldName} within Component: ${this.componentName}`;
                } else {
                    return defaultValue;
                }
            }

            let fieldObject = this.getTextObject(this.componentTextObject, fieldParts.reverse());
            fieldText = fieldObject[field];
            if (fieldText === undefined) {
                if (isRequired) {
                    throw `Error: can't find text for field: ${fieldName} within Component: ${this.componentName}`;
                } else {
                    return defaultValue;
                }
            }

        } catch (e: any) {
            logErrorWithOutAlert('CMS Error', e);
            fieldText = defaultValue;
        }

        return fieldText;
    }

    /**
     * Recurse the object structure returned from the CMS system to find the text object for this component.
     * @param componentTextObject The cms Text object structure to find the text for.
     * @param fieldNames The list of fields to navigate down the structure.
     */
    private getTextObject(componentTextObject: any, fieldNames: string[]): any {
        let fieldName = fieldNames.pop();
        if (fieldName !== undefined && fieldName.length > 0) {
            let textObject: any = componentTextObject[fieldName];
            if (textObject === undefined) {
                throw `Error: can't find text object for field: ${fieldName} within Component: ${this.componentName}`;
            }

            return this.getTextObject(textObject, fieldNames);
        }

        return componentTextObject;
    }

    private getRootObject(componentTextObject: any, rootPath: string): any {
        return this.getTextObject(componentTextObject, this.getTextParts(rootPath).reverse());
    }

    private getTextParts(textSearch: string): string[] {
        let parts = textSearch.split('.');
        return parts;
    }

    getAccessibilityText(textKey: string): string {
        return (textKey) ? this.componentTextObject?.accessibility[textKey] : "";
    }
}