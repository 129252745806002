import * as React from 'react';
import { EmptyObject } from '../../misc/common';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { renderInputField } from '../form-field';
import ReCaptcha from '../recaptcha/recaptcha';
import { CmsText } from '../../misc/cms-text';
import { CmsManifestMetaData } from '../../misc/cms-manifest-metadata';

const validators = require('redux-form-validators');

/**
 * Names of the form fields (created with <Field>)
 * Must match the 'name' given to the fields
 * This will be available on form submit
 */
export type SsoEmailGateFormFields = {
    email: string,
    captchaResponse: string,
    privacyPolicyCustomCheckboxAccepted: boolean | null,
    manifestMetaData: CmsManifestMetaData
};

export type SsoEmailGateStateProps = {
    componentText: any;
    docMetaData: any;
    showCaptcha: boolean;
    captchaLanguageCulture: string;
    privacyPolicyCustomCheckboxAccepted: boolean | null;
    errorMessage: string;
};

export type SsoEmailGateDispatchProps = {
    submitSsoEmailGate(values: SsoEmailGateFormFields): Promise<void>;
    captchaComplete(formName: string, response: string): void;
    formFieldChange(formName: string, fieldName: string, value: any): void;
};

type SsoEmailGateInternalState = {
    isCaptchaComplete: boolean;
};

export type SsoEmailGateProps = 
    SsoEmailGateDispatchProps 
    & SsoEmailGateStateProps;

/**
 * Single-Sign-On email gate page.
 * This page asks a user to enter their email address and complete ReCaptcha if enabled.
 * Once email address has been entered, it is sent to the server to determine the next course of action
 */
class SsoEmailGate extends React.Component<SsoEmailGateProps & InjectedFormProps<SsoEmailGateFormFields, SsoEmailGateProps>, SsoEmailGateInternalState> implements React.ComponentLifecycle<SsoEmailGateProps, EmptyObject> {
    constructor(props: SsoEmailGateProps & InjectedFormProps<SsoEmailGateFormFields, SsoEmailGateProps>) {
        super(props);

        this.state = {
            isCaptchaComplete: !this.props.showCaptcha
        };

        this.captchaComplete = this.captchaComplete.bind(this);
        this.updatePrivacyPolicyResponse = this.updatePrivacyPolicyResponse.bind(this);
    }

    componentDidMount() {
        this.updatePrivacyPolicyResponse();

        // Set the values of form fields that are not displayed. This enables values to be available on submit
        this.props.formFieldChange(SsoEmailGateFormName, 'manifestMetaData', new CmsManifestMetaData(this.props.docMetaData));
    }

    /**
     * Save this state value (set in privacy policy page) as a form field response, so it's easily accessible when submitting the form
     */
    updatePrivacyPolicyResponse() {
        this.props.formFieldChange(SsoEmailGateFormName, 'privacyPolicyCustomCheckboxAccepted', this.props.privacyPolicyCustomCheckboxAccepted);
    }

    captchaComplete(response: string) {
        this.props.captchaComplete(SsoEmailGateFormName, response);
        this.setState({
            isCaptchaComplete: true
        });
    }

    render() {
        let cmsText: CmsText = new CmsText(this.props.componentText, 'Open Invitation');
        let disableSubmit = this.props.submitting || this.props.pristine || !this.state.isCaptchaComplete;
        return (
            <div className="content">
                <div className="panel-info">
                    <div className="form-control-static signinpanel-greeting">
                        <h4 className="signinpanel-greeting-accent">
                            {cmsText.get('personalDetails', 'Personal details')}
                        </h4>
                    </div>
                    <p className="signinpanel-statement">
                        {cmsText.get('enterEmail', 'Please enter your email address below, then click the submit button.')}
                    </p>
                    <div className="panel-border" />
                </div>
                <Form 
                    onSubmit={this.props.handleSubmit(this.props.submitSsoEmailGate)} 
                    className="signin-form" 
                    autoComplete="off">
                    {this.props.errorMessage.length > 0 && <p className="form-error">{this.props.errorMessage}</p>}
                    <Field
                        id="oiEmail"
                        className="form-field-input"
                        name="email"
                        label={cmsText.get('email', 'Email')}
                        component={renderInputField}
                        type="text"
                        validate={[
                            validators.required({ msg: cmsText.get('emailRequired', 'required') }), 
                            validators.email({ msg: cmsText.get('invalidEmail', 'Invalid email address') })
                        ]}
                        autoComplete="off"
                    />
                    <div className="form-control-static form-field-input">
                        {/* key is per language because we want a *new* component per language, rather than the same updated component. */}
                        {this.props.showCaptcha && <ReCaptcha 
                                                        captchaComplete={this.captchaComplete} 
                                                        languageCulture={this.props.captchaLanguageCulture} 
                                                        key={'recaptcha' + this.props.captchaLanguageCulture} 
                                                    />
                        }
                    </div>
                    <div className="form-button">
                        <button className="accept" type="submit" disabled={disableSubmit}>
                            {cmsText.get('submit', 'Submit')}
                        </button>
                    </div>
                </Form>
            </div>
        );
    }
}

export const SsoEmailGateFormName = 'ssoemailgate';

const SsoEmailGateForm = reduxForm<SsoEmailGateFormFields, SsoEmailGateProps>({
    form: SsoEmailGateFormName
})(SsoEmailGate);

export default SsoEmailGateForm;